import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-780fd68a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column flex-md-row h-100 w-100" }
const _hoisted_2 = {
  class: "d-flex justify-content-center align-items-center flex-column font-color-white",
  style: {"width":"100%","height":"100%","position":"relative"}
}
const _hoisted_3 = {
  href: "#",
  style: {"position":"absolute","top":"50px","left":"50px"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "d-flex flex-grow-1 w-md-50 h-100 bg-wither" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderStore.status)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "d-flex flex-grow-1 flex-column flex-column-fluid w-md-50 h-20 h-md-100 background-imge-layout-login",
        style: _normalizeStyle(`background-image: url(${_ctx.urlBackgroundImage});`)
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, [
            _createElementVNode("img", {
              alt: "Logo",
              style: {"width":"150px"},
              src: _ctx.urlLogo
            }, null, 8, _hoisted_4)
          ])
        ])
      ], 4),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}