
import { defineComponent, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import urlBackgroundImage from "@/assets/layout/background-layout-login-optimized.png"
import urlLogo from "@/assets/layout/logo-indicar-original-azul-horizontal-1.png";
import KTLoader from "@/components/Loader.vue";
import { loaderLogo } from "@/core/helpers/config";
import { useLoaderStore } from "@/store/LoaderStore";

export default defineComponent({
  name: "auth",
  components: { KTLoader },
  setup() {
    const host = window.location.host;
    const distribuidor = distribuidorConfig.filter(
      (e) => e.dominio_gestao == host
    )[0];

    const store = useStore();
    const loaderStore = useLoaderStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    watch(() => loaderStore.status, () => {
      if(loaderStore.status) {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading")
      } else {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading")
        const htmlElement:HTMLElement | null = document.querySelector(".drawer-overlay")
        if(htmlElement) {
          htmlElement!.click()
        }
      }
    })
    return {
      distribuidor,
      urlLogo,
      urlBackgroundImage,
      loaderLogo,
      loaderStore
    };
  },
});
